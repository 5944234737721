import React from "react"
import styled from "styled-components"

import ArticlePreview from "./article-preview"
import Project from "../components/project"

const ArticlePreviewWrapper = styled.div`
  grid-column-end: span 4;
  scroll-margin-top: 100px;
  @media only screen and (min-width: 666px) {
    &:nth-child(3n + 1) {
      grid-column-start: 3;
    }
  }
  @media only screen and (max-width: 666px) {
    &:nth-child(even) {
      grid-column-start: 2;
    }
  }
  &:first-child {
    /* TODO: what if this has a landscape photo? */
    grid-column: 2 / -2;
    p {
      display: block;

      &:only-child {
        columns: 2;
      }
    }
    li p:only-child {
      columns: 1;
    }
    @media only screen and (max-width: 666px) {
      grid-column: 1 / -1;
    }
    @media only screen and (max-width: 480px) {
      p {
        columns: 1;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    grid-column-end: span 5;
    &:nth-child(even) {
      grid-column-start: 1;
    }
  }
`

const ArticlePreviewWrapperPortrait = styled(ArticlePreviewWrapper)``

const ArticlePreviewWrapperLandscape = styled(ArticlePreviewWrapper)``

const ArticlePreviews = styled.div`
  margin-left: 0em;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: ${props => (props.$teaser ? `0 2em` : `2em`)};

  @media only screen and (min-width: 666px) {
    ${ArticlePreviewWrapperPortrait} + ${ArticlePreviewWrapperPortrait}:nth-child(3n),
    ${ArticlePreviewWrapperLandscape} + ${ArticlePreviewWrapperLandscape}:nth-child(3n) {
      margin-top: 8em;
    }
  }

  @media only screen and (max-width: 666px) {
    grid-template-columns: repeat(5, 1fr);
    margin-left: 0em;
  }
`

const ProjectWrapper = styled.div`
  grid-column: 1 / -1;
  padding: 1em 0;
`

const ArticleGrid = ({ articles, project, teaser = false, ...props }) => {
  const displayedArticles = teaser ? [null, ...articles] : articles

  const articlePreviews = displayedArticles.map((article, index) => {
    if (!article) {
      return <ArticlePreviewWrapper key={index} />
    }

    const aspectRatio = article.coverImage.fluid.aspectRatio

    const orientation = aspectRatio > 1 ? "landscape" : "portrait"

    const componentByOrientation = {
      landscape: ArticlePreviewWrapperLandscape,
      portrait: ArticlePreviewWrapperPortrait,
    }

    const Wrapper = componentByOrientation[orientation]

    return (
      <Wrapper id={`meldung-${article.slug}`} key={article.slug}>
        <ArticlePreview {...article} />
      </Wrapper>
    )
  })

  const showProject = Boolean(project) && displayedArticles.length > 5

  const gridItems = showProject
    ? [
        ...articlePreviews.slice(0, 3),
        <ProjectWrapper key="project">
          <Project {...project} reversed />
        </ProjectWrapper>,
        ...articlePreviews.slice(3),
      ]
    : articlePreviews

  return (
    <ArticlePreviews $teaser={teaser} {...props}>
      {gridItems}
    </ArticlePreviews>
  )
}

export default ArticleGrid

import React from "react"
import styled from "styled-components"
import { useIntersection } from "react-use"

import {
  UnstyledLink,
  Image as GatsbyImage,
  CallToAction,
  ArticleSubheading,
  Paragraph,
} from "./shared"
import { RichText } from "../api/contentful"
import * as articleUtil from "../util/article"

// TODO: extracted because of syntax highlighter bug
const readingTimeText = props =>
  props.$hasBeenInView ? `${props.$readingTime} min` : ``

const ContainerWithoutReadingTime = styled.div`
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 1.8em;
  z-index: 0;
`

const ContainerWithReadingTime = styled(ContainerWithoutReadingTime)`
  &::before {
    content: '${readingTimeText}';
    padding: 0.335em 0;
    padding-right: ${props => (props.$hasBeenInView ? `0.8em` : `0`)};
    width: ${props =>
      props.$hasBeenInView
        ? Math.min(props.$readingTime / articleUtil.maxReadingTime, 1) * 100
        : 0}%;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    white-space: nowrap;
    font-weight: light;
    color: ${props =>
      props.$hasBeenInView ? props.theme.readingTimeColor : "transparent"};
    background-color: ${props => props.theme.readingTimeBackgroundColor};
    transition-property: width, padding, color;
    transition-duration:
      ${props => props.theme.readingTimeGrowDuration},
      ${props => props.theme.readingTimeGrowDuration},
      ${props => props.theme.readingTimeFadeInDuration};
    transition-delay:
      ${props => props.theme.readingTimeGrowDelay},
      ${props => props.theme.readingTimeGrowDelay},
      ${props => props.theme.readingTimeFadeInDelay};
  }
`

const imageMaxHeight = `30em`
const CoverImage = styled(GatsbyImage)`
  max-width: calc(${imageMaxHeight} * ${props => props.fluid.aspectRatio});
`

const PublishDate = styled.time`
  display: inline-block;
  margin-top: 0.8em;
  padding: 0.1em 3em 0 1rem;
  line-height: 1.3;
  font-weight: bold;
  color: ${props => props.theme.publishDateColor};
  background-color: ${props => props.theme.publishDateBackgroundColor};
  opacity: 1;
  transition: opacity 10s;
`

const Title = styled.h4`
  font-size: 1.9rem;
  line-height: 1em;
  margin: 0.335em 0;
  padding-left: ${props => (props.$isPost ? `0` : `1rem`)};

  @media only screen and (max-width: 480px) {
    font-size: 1.6rem;
  }
`

const Excerpt = styled.div`
  font-size: 1rem;
  padding-left: ${props => (props.$isPost ? `0` : `1rem`)};
  & > p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > ul,
  & > ol {
    padding-left: 1.25em;
  }
`

const useHasBeenInView = () => {
  const intersectionRef = React.useRef(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  })

  const [hasBeenInView, setHasBeenInView] = React.useState(false)
  if (!hasBeenInView && intersection && intersection.intersectionRatio) {
    setHasBeenInView(true)
  }

  return { intersectionRef, hasBeenInView }
}

const ArticlePreview = ({
  title,
  slug,
  text,
  publishDate,
  coverImage,
  style,
}) => {
  const { intersectionRef, hasBeenInView } = useHasBeenInView()

  const readingTime = articleUtil.readingTime(text)

  const isPost = articleUtil.isPost(text)

  const previewText = isPost
    ? text
    : articleUtil.documentWithFirstNodeOnly(text)

  const Container = isPost
    ? ContainerWithoutReadingTime
    : ContainerWithReadingTime

  return (
    <>
      <Container
        ref={intersectionRef}
        $readingTime={readingTime}
        $hasBeenInView={hasBeenInView}
        style={style}
      >
        {coverImage &&
          (isPost ? (
            // TODO: alt
            <CoverImage fluid={coverImage.fluid} alt="" />
          ) : (
            // TODO: alt
            <UnstyledLink to={`/artikel/${slug}`} title={`Artikel: ${title}`}>
              <CoverImage fluid={coverImage.fluid} alt="" />
            </UnstyledLink>
          ))}
        <PublishDate>
          {new Intl.DateTimeFormat("de-DE", {
            day: "numeric",
            month: "short",
            year: "numeric",
          }).format(new Date(publishDate))}
        </PublishDate>
        <Title $isPost={isPost}>
          {isPost ? (
            title
          ) : (
            <UnstyledLink to={`/artikel/${slug}`}>{title}</UnstyledLink>
          )}
        </Title>
        <Excerpt $isPost={isPost}>
          <RichText
            node={previewText}
            h3Component={ArticleSubheading}
            paragraphComponent={Paragraph}
          />
        </Excerpt>
      </Container>
      {!isPost && (
        <CallToAction to={`/artikel/${slug}`} style={{ fontSize: "1rem" }}>
          zum Artikel
        </CallToAction>
      )}
    </>
  )
}

export default ArticlePreview

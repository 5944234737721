const allTextNodes = node => {
  if (node.nodeType === "text") {
    return [node]
  }
  if (!node.content) {
    return []
  }
  return node.content.flatMap(childNode => allTextNodes(childNode))
}

export const numWordsInNode = node => {
  const words = allTextNodes(node).flatMap(node => node.value.split(/\s+/))

  return words.length
}

const wordsPerMinute = 200

export const readingTime = text => {
  const numWords = numWordsInNode(text.json)
  const minutes = Math.ceil(numWords / wordsPerMinute)

  return minutes
}

export const isPost = text => readingTime(text) < 1

export const documentWithFirstNodeOnly = document => {
  return {
    ...document,
    json: {
      ...document.json,
      content: document.json.content.slice(0, 1),
    },
  }
}

export const maxReadingTime = 8

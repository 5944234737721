import React from "react"
import styled from "styled-components"
import { useDrag } from "react-use-gesture"
import { CSSTransition } from "react-transition-group"

import { ReactComponent as LeftArrowSvg } from "../images/left-arrow.svg"
import { ReactComponent as RightArrowSvg } from "../images/right-arrow.svg"

const ColumnSvg = props => (
  <svg
    width="6"
    height="30"
    viewBox="0 0 24 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="column">
        <path d="M0 14L24 0V120H0V14Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#column)">
      <path d="M0 0L24 0V120H0V0Z" />
      <path d="M0 0L24 0V120H0V0Z" className="progress" />
    </g>
  </svg>
)

// TODO: imageMaxWidth as well?
const imageMaxHeight = `30em`
const imageMaxHeightNarrow = `20em`

const buttonOffset = "24px"
const ImageWithButtons = styled.div`
  position: relative;
  width: 100%;
  height: ${imageMaxHeightNarrow};

  @media only screen and (min-width: 666px) {
    height: ${imageMaxHeight};
    ${props => (props.$reversed ? `margin-right` : `margin-left`)}: 2em;
  }
`

const minShowDurationMs = 4000
const fadeDurationMs = 400

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  min-width: 80vw;

  @media only screen and (min-width: 800px) {
    min-width: 40em;
  }

  &.image-enter {
    opacity: 0;
  }

  &.image-enter-active {
    opacity: 1;
    transition: opacity ${fadeDurationMs}ms;
  }

  &.image-exit {
    opacity: 1;
  }

  &.image-exit-active {
    opacity: 0;
    transition: opacity ${fadeDurationMs}ms;
  }
`

const Buttons = styled.div`
  position: absolute;
  bottom: calc(-1 * ${buttonOffset});
  ${props => (props.$reversed ? `right` : `left`)}: 0;
  padding: 0 6px;
  display: flex;
  align-items: flex-end;
`

const Button = styled.button.attrs(() => ({ type: "button" }))`
  padding: 4px 6px;
  line-height: 0;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 666px) {
    min-width: 40px;
  }
`

const LeftArrow = styled(LeftArrowSvg)``

const RightArrow = styled(RightArrowSvg)``

const columnFillShowDuration = 200
const columnFillHideDuration = 100

const Column = styled(ColumnSvg)`
  .progress {
    fill: ${props => props.theme.imageSliderProgressColor};
    transform: ${props => (props.$isActive ? `scaleY(1)` : `scaleY(0)`)};
    transform-origin: bottom;
    transition: transform
      ${props =>
        props.$isActive
          ? props.$autoRotate
            ? `${props.$autoRotateInterval}ms linear`
            : `${columnFillShowDuration}ms`
          : `${columnFillHideDuration}ms`};
  }
`

const portraitWidth = 315
const portraitHeight = 465
const squareSize = 380
const landscapeHeight = 300
const landscapeWidth = 480

const dimensionsByFormat = {
  portrait: {
    width: `calc(${imageMaxHeight} * ${portraitWidth}/${portraitHeight})`,
    height: imageMaxHeight,
  },
  square: {
    width: `calc(${imageMaxHeight} * ${squareSize}/${portraitHeight})`,
    height: `calc(${imageMaxHeight} * ${squareSize}/${portraitHeight})`,
  },
  landscape: {
    width: `calc(${imageMaxHeight} * ${landscapeWidth}/${portraitHeight})`,
    height: `calc(${imageMaxHeight} * ${landscapeHeight}/${portraitHeight})`,
  },
}

const ratioByFormat = {
  portrait: portraitWidth / portraitHeight,
  square: 1,
  landscape: landscapeWidth / landscapeHeight,
}

const imageFormat = aspectRatio => {
  if (aspectRatio < 1 / 1.1) {
    return "portrait"
  } else if (aspectRatio > 1.3 / 1) {
    return "landscape"
  } else {
    return "square"
  }
}

const imageDimensions = aspectRatio => {
  const format = imageFormat(aspectRatio)
  return dimensionsByFormat[format]
}

const Image = styled.img`
  position: absolute;
  ${props => (props.$reversed ? `right` : `left`)}: 0;
  bottom: 0;
  width: ${props => imageDimensions(props.$aspectRatio).width};
  height: ${props => imageDimensions(props.$aspectRatio).height};
  object-fit: cover;

  @media only screen and (max-width: 800px) {
    width: 100vw;
    height: calc(
      100vw / ${props => ratioByFormat[imageFormat(props.$aspectRatio)]}
    );
  }
`

const Gallery = ({ images, reversed }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [autoRotate, setAutoRotate] = React.useState(true)
  const [autoRotateInterval] = React.useState(
    () => minShowDurationMs + Math.random() * 1000
  )

  const setCurrentIndexAndStop = index => {
    setCurrentIndex(index)
    setAutoRotate(false)
  }

  const safeIndex =
    ((currentIndex % images.length) + images.length) % images.length

  React.useEffect(() => {
    if (!autoRotate) {
      return () => {}
    }

    let timeout
    if (images) {
      timeout = setTimeout(() => {
        setCurrentIndex(index => index + 1)
      }, autoRotateInterval)
    }

    return () => clearTimeout(timeout)
  }, [images, currentIndex, autoRotate, autoRotateInterval])

  const arrowButtons = (
    <>
      <Button
        title="voriges Bild"
        onClick={() => setCurrentIndexAndStop(index => index - 1)}
      >
        <LeftArrow />
      </Button>
      <Button
        title="nächstes Bild"
        onClick={() => setCurrentIndexAndStop(index => index + 1)}
      >
        <RightArrow />
      </Button>
    </>
  )

  const imageButtons = images.map((_, index) => (
    <Button
      title={`Bild ${index + 1}`}
      onClick={() => setCurrentIndexAndStop(index)}
      key={index}
    >
      <Column
        $isActive={index === safeIndex}
        $autoRotate={autoRotate}
        $autoRotateInterval={autoRotateInterval}
      />
    </Button>
  ))

  const bind = useDrag(({ swipe: [swipeX] }) => {
    if (swipeX > 0) {
      setCurrentIndexAndStop(index => index - 1)
    } else if (swipeX < 0) {
      setCurrentIndexAndStop(index => index + 1)
    }
  })

  // number of images is guaranteed to be constant
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const imageRefs = images.map(() => React.useRef())

  return (
    <ImageWithButtons $reversed={reversed}>
      {images.map((image, index) => {
        const { aspectRatio } = image.fluid

        return (
          <CSSTransition
            in={index === safeIndex}
            mountOnEnter
            unmountOnExit
            timeout={fadeDurationMs + 50}
            nodeRef={imageRefs[index]}
            classNames="image"
            key={index}
          >
            <ImageWrapper {...bind()} ref={imageRefs[index]} key={index}>
              {/* TODO: fetch appropriate size  */}
              {/* TODO: alt  */}
              <Image
                src={image.fluid.src}
                alt=""
                $reversed={reversed}
                $aspectRatio={aspectRatio}
              />
            </ImageWrapper>
          </CSSTransition>
        )
      })}
      <Buttons $reversed={reversed}>
        {reversed ? (
          <>
            {arrowButtons}
            {imageButtons}
          </>
        ) : (
          <>
            {imageButtons}
            {arrowButtons}
          </>
        )}
      </Buttons>
    </ImageWithButtons>
  )
}

export default Gallery

import React from "react"
import styled from "styled-components"

import { RichText } from "../api/contentful"
import Gallery from "./gallery"
import { ReactComponent as Volume } from "../images/volume.svg"
import { ReactComponent as Cloud } from "../images/cloud.svg"

// "Als Faustregel kann gelten, dass 1 m3 Holz rund 700 kg CO2-Emissionen verhindert, wenn er als Werkstoff eingesetzt wird."
// - Wald und Holz 4/10
// https://www.waldwissen.net/assets/wald/klima/wandel_co2/wsl_effektiver_klimaschutz/download/wsl_effektiver_klimaschutz_originalartikel.pdf.pdf
const kgCo2PerM3Timber = 700

const InformationColumn = styled.div`
  flex: 1;
  @media only screen and (max-width: 666px) {
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

const ProjectDescription = styled.div`
  max-height: 600px;
  overflow: auto;
`

const GalleryColumn = styled.div`
  max-width: 100%;
  flex: 2;
  position: relative;
  padding-bottom: 1.3em;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const Row = styled.div`
  margin-bottom: 3em;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: ${props => (props.reversed ? `row-reverse` : `row`)};
  align-items: flex-end;

  @media only screen and (max-width: 960px) {
    ${InformationColumn} {
      min-width: 30%;
    }
  }

  @media only screen and (max-width: 666px) {
    flex-direction: column-reverse;

    ${GalleryColumn} {
      min-height: 20em;
    }
  }
`

const ProjectTitle = styled.h4`
  margin: 0.8em 0 0.4em 0;
  font-size: 1.7rem;
  line-height: 1em;
`

const FigureList = styled.dl`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  align-items: baseline;
  row-gap: 1em;
  width: 100%;
  text-align: center;
`

const FigureKey = styled.dt`
  height: 100%;
  border-bottom: 2px solid;
`

const FigureIcon = styled.dd`
  margin: 0;
`

const FigureValue = styled.dd`
  margin: 0;
`

const Project = ({
  title,
  description,
  timberVolume,
  photos,
  reversed = false,
}) => {
  return (
    <Row reversed={reversed}>
      <InformationColumn>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectDescription>
          <RichText node={description} />
        </ProjectDescription>
        <FigureList>
          <FigureKey>verbautes Holz</FigureKey>
          <FigureIcon>
            <Volume width={80} />
          </FigureIcon>
          <FigureValue>
            {timberVolume}&nbsp;m<sup>3</sup>
          </FigureValue>

          <FigureKey>
            gebundenes CO<sub>2</sub>
          </FigureKey>
          <FigureIcon>
            <Cloud width={80} />
          </FigureIcon>
          <FigureValue>
            {Intl.NumberFormat("de-DE").format(timberVolume * kgCo2PerM3Timber)}
            &nbsp;kg
          </FigureValue>
        </FigureList>
      </InformationColumn>
      <GalleryColumn>
        <Gallery images={photos} reversed={reversed} />
      </GalleryColumn>
    </Row>
  )
}

export default Project
